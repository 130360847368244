import React from "react";
import { giftTitle } from "../../../configuration";
import { utils } from "../../utils/utils";
import "./index.scss";
interface Props {
  orders?: any;
}

const OrderHistoryItem: React.FC<Props> = ({ orders = [] }) => {
  const itemPagekaging = (item: any) => {
    if (!item.packaging) {
      return (
        <>
          <span>ギフト包装 有り</span>
          <span>{utils.renderGift(item.type_packaging)}</span>
        </>
      );
    }
    return <span>ギフト包装 無し</span>;
  };

  return orders.map((order: any) => {
    let itemPrice = `¥${order.order_price
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    return (
      <div className="order_history_item" key={order.id}
        onClick={() => { window.location.href = `/mypage_history/${order.id}` }}
      >
        <div className="order_history_item-header">
          <div className="order_history_item-date">
            注文日：
            <span className="order-date">{order.order_date}</span>
          </div>
          <div className="order_history_item-price">合計：{itemPrice}</div>
        </div>
        <div className="order_history_item-container">
          {utils
            .compressOrdersInMypage(order.order_items)
            .map((item: any, index: number) => {
              return (
                <div
                  className="order_history_item-order_item"
                  key={`${item.id}_${index}`}
                >
                  <div className="order_history_item-img">
                    <img
                      src={item.cover_image_url}
                      alt={item.cover_image_name}
                    />
                  </div>
                  <div className="order_history_item-content">
                    <div>
                      <div className="order_history_item-title">
                        {item.is_gift ? giftTitle : item.title}
                      </div>
                      <div className="order_history_item-packaging_type">
                        {itemPagekaging(item)}
                      </div>
                    </div>
                    <div className="order_history_item-quantity">
                      数量：{item.amount}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  });
};

export default OrderHistoryItem;
